import React from 'react'
import { Form } from 'react-bootstrap';
import logo from '../images/optec-bc-fav.svg';
import { roundDown, roundToNearestHundreths, roundUPtoNearestHundreth } from '../lib/helpers';
import { Button } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Menu = ({ 
    handleSetModule, module, 
    setVariation, variation,
    indoorOutdoor, indoorOutdoorToggler,
    
    handleBorderToggle,

    handleWidthFeetChange, handleWidthInchesChange, 
    wallWidthFeet, wallWidthInches,
    handleHeightFeetChange,  handleHeightInchesChange,
    wallHeightFeet, wallHeightInches,
    
    handleFixedWidthFeetChange, handleFixedWidthInchesChange,
    fixedWallWidthFeet, fixedWallWidthInches,
    handleFixedHeightFeetChange, handleFixedHeightInchesChange,
    fixedWallHeightFeet, fixedWallHeightInches,
    
    setPxWidth, setPxHeight,
    pxWidth, pxHeight,
   
    setXMargins, setYMargins,

    setWallWidthFeet, setWallWidthInches,
    setWallHeightFeet, setWallHeightInches,
    setFivesWidth, setFoursWidth, setThreesWidth, setTwosWidth, 
    setFivesNeededForWidth, setFoursNeededForWidth, setThreesNeededForWidth, setTwosNeededForWidth,
    setWpNumberOfRows, setWpPhysicalHeight,
    setFivesResolution,setFoursResolution, setThreesResolution, setTwosResolution, setWpHeightResolution,

    inch, foot, additionalModules
}) => {


  const renderVariations = (module = false) => {
    if (!module) return;
      let rendered = module.variations.map(v => {
        let singleModuleWidth
        let singleModuleHeight
        let widthFeet
        let widthInches
        let heightFeet
        let heightInches
 
        if(module.physical_dimensions_inches) {
          singleModuleWidth = module.physical_dimensions_inches.width * inch;
          singleModuleHeight = module.physical_dimensions_inches.height * inch;
        }else{
          singleModuleWidth = v.physical_dimensions_inches.width * inch;
          singleModuleHeight = v.physical_dimensions_inches.height * inch;
        }

        let modulesNeededForWidth = roundDown(pxWidth / singleModuleWidth);
        let modulesNeededForHeight = roundDown(pxHeight / singleModuleHeight);

        if(module.ruleset.tileHorizontal)  { 
          widthFeet = roundToNearestHundreths(singleModuleWidth * modulesNeededForWidth / foot);
          widthInches = roundToNearestHundreths(singleModuleWidth * modulesNeededForWidth % inch);
        }else{
          widthFeet = roundToNearestHundreths(singleModuleWidth / foot);
          widthInches = roundToNearestHundreths(singleModuleWidth % inch);
        }

        if(module.ruleset.tileVertical){
          heightFeet = roundToNearestHundreths(singleModuleHeight * modulesNeededForHeight / foot);
          heightInches = roundToNearestHundreths(singleModuleHeight * modulesNeededForHeight % inch);
        }else{
          heightFeet = roundToNearestHundreths(singleModuleHeight / foot);
          heightInches = roundToNearestHundreths(singleModuleHeight % inch);
        }

        if(wallWidthFeet + wallWidthInches > 0 && wallHeightFeet + wallHeightInches > 0){
          return(
            <option
              id={Number(v.id)}
              key={Number(v.id)}
              value={Number(v.id)}
            >
            {v.name} - {widthFeet}'{widthInches}" x {additionalModules 
                ? roundToNearestHundreths(heightFeet + ((module?.physical_dimensions_inches.height % inch) / 2)) + "' " + roundToNearestHundreths(heightInches + ((module?.physical_dimensions_inches.height % inch) / 2)) + '"'
                : heightFeet + "' " + heightInches + '"'}
            </option>
          )
        }else{
          return(
            <option
              id={Number(v.id)}
              key={Number(v.id)}
              value={Number(v.id)}
            >
            {v.name} [Enter display dimensions to preview size]
            </option>
          );
        }
      });
      return rendered;
  }

    const setPreset = (preset) => {
      if(module == undefined) return;
      if(indoorOutdoor == 'indoor' || preset.width == 1920 && preset.height == 1080){

        let singleModuleHeight = 0;
        let singleModuleWidth = 0;

        let fivesWidth = 0;
        let foursWidth = 0;
        let threesWidth = 0;
        let twosWidth = 0;
        let wpHeight = 0;

  
        if(module.physical_dimensions_inches) {
          singleModuleWidth = module.physical_dimensions_inches.width * inch;
          singleModuleHeight = module.physical_dimensions_inches.height * inch;
        }else if(module.name.includes('Opt-Slim')){
          wpHeight = module.variations[0].physical_dimensions_inches.height * inch;
          fivesWidth = module.variations[3].physical_dimensions_inches.width * inch;
          foursWidth = module.variations[2].physical_dimensions_inches.width * inch;
          threesWidth = module.variations[1].physical_dimensions_inches.width * inch;
          twosWidth = module.variations[0].physical_dimensions_inches.width * inch;
        }else{
          singleModuleWidth = module.variations[variation].physical_dimensions_inches.width * inch;
          singleModuleHeight = module.variations[variation].physical_dimensions_inches.height * inch; 
        }
      
  
        let moduleResolutionWidth;
        let moduleResolutionHeight;
  
        let _modsNeededForWidth;
        let _modsNeededForHeight;

        let fivesResolution;
        let foursResolution;
        let threesResolution;
        let twosResolution;
        let wpHeightResolution;

        let fivesNeeded;
        let foursNeeded;
        let threesNeeded;
        let twosNeeded;
        let wpNumberOfRows;

        if(module?.name.includes('Opt-Slim')){
          
          fivesResolution = module.variations[3].resolution.width;
          foursResolution = module.variations[2].resolution.width;
          threesResolution = module.variations[1].resolution.width;
          twosResolution = module.variations[0].resolution.width;
          wpHeightResolution = module.variations[0].resolution.height;
          
          fivesNeeded = Math.floor(preset.width / fivesResolution);
          let remaining = 0;
          
          if (fivesNeeded > 0) {
            remaining = preset.width % (fivesResolution * fivesNeeded);
            console.log('remaining space after the fives: ', remaining);
          }
          foursNeeded = Math.floor(remaining / foursResolution);
          
          if (foursNeeded > 0) {
            if(fivesNeeded > 0){
              remaining = remaining % (foursResolution * foursNeeded);
            }else{
              remaining = preset.width % (foursResolution * foursNeeded);
            }
            console.log('remaining space after the fours: ', remaining);
          }
          threesNeeded = Math.floor(remaining / threesResolution);

          if (threesNeeded > 0) {
            if (foursNeeded > 0 || fivesNeeded > 0) {
              remaining = remaining % (threesResolution * threesNeeded);
            } else {
              remaining = preset.width % (threesResolution * threesNeeded);
            }

            console.log('remaining space after the threes: ', remaining);
          }
          twosNeeded = Math.floor(remaining / twosResolution);

          if (twosNeeded > 0) {
            if (foursNeeded > 0 || threesNeeded > 0 || fivesNeeded > 0) {
              remaining = remaining % (twosResolution * twosNeeded);
            } else {
              remaining = preset.width % (twosResolution * twosNeeded);
            }
            console.log('remaining space after the twos: ', remaining);
          }
          wpNumberOfRows = Math.ceil(preset.height / wpHeightResolution);

          let tempY = (wpNumberOfRows * wpHeightResolution) % preset.height;
          let tempX = (remaining / 2);

          setYMargins(tempY);
          setXMargins(tempX);

          setFivesResolution(fivesResolution);
          setFoursResolution(foursResolution);
          setThreesResolution(threesResolution);
          setTwosResolution(twosResolution);
          setWpHeightResolution(wpHeightResolution);

          setFivesWidth(fivesWidth);
          setFoursWidth(foursWidth);
          setThreesWidth(threesWidth);
          setTwosWidth(twosWidth);

          setWpNumberOfRows(wpNumberOfRows);
          setWpPhysicalHeight(wpHeight);

          setFivesNeededForWidth(fivesNeeded);
          setFoursNeededForWidth(foursNeeded);
          setThreesNeededForWidth(threesNeeded);
          setTwosNeededForWidth(twosNeeded);


          let totalWidth = fivesNeeded * fivesWidth + foursNeeded * foursWidth + threesNeeded * threesWidth + twosNeeded * twosWidth + remaining;
          console.log("total width: ", totalWidth);

          let feetWidth = totalWidth / foot;
          let inchesWidth = (totalWidth - feetWidth) % inch;

          setWallWidthFeet(roundUPtoNearestHundreth(feetWidth));
          setWallWidthInches(roundUPtoNearestHundreth(inchesWidth));

          setPxWidth(totalWidth);
          setPxHeight(wpNumberOfRows * wpHeight);

          let totalHeight = wpNumberOfRows * wpHeight;
          let feetHeight = totalHeight / foot;
          let inchesHeight = (totalHeight - feetHeight) % inch;

          setWallHeightFeet(roundUPtoNearestHundreth(feetHeight));
          setWallHeightInches(roundUPtoNearestHundreth(inchesHeight));



        }else{
          moduleResolutionWidth = module.variations[variation].resolution.width;
          moduleResolutionHeight = module.variations[variation].resolution.height;
          _modsNeededForWidth = 0;
          _modsNeededForHeight = 0; 

          while (moduleResolutionWidth * _modsNeededForWidth < preset.width) {
            _modsNeededForWidth++;
          }

          while (moduleResolutionHeight * _modsNeededForHeight < preset.height) {
            _modsNeededForHeight++;
          }

          let _displayWidthFeet = roundUPtoNearestHundreth(singleModuleWidth * _modsNeededForWidth / foot);
          let _displayWidthInches = roundUPtoNearestHundreth(singleModuleWidth * _modsNeededForWidth % inch);
          let _displayHeightFeet = roundUPtoNearestHundreth(singleModuleHeight * _modsNeededForHeight / foot);
          let _displayHeightInches = roundUPtoNearestHundreth(singleModuleHeight * _modsNeededForHeight % inch);

          setWallWidthFeet(_displayWidthFeet);
          setWallWidthInches(_displayWidthInches);
          setWallHeightFeet(_displayHeightFeet);
          setWallHeightInches(_displayHeightInches);
        }
      }else{
        setWallWidthFeet(preset.width);
        setWallHeightFeet(preset.height);
      }
    }

    function openSpecs(){
      document.getElementById("details-modal").style.display = "block";
    }


  return (
            <div id="menu">
                <div id="menu-header">
                    <h3>Optec Visualizer</h3>
                    <Button variant="light" onClick={()=> openSpecs()}><FontAwesomeIcon icon={faDownload} /></Button>
                </div>
                <div>
                    <img src={logo} id="lone-logo" />
                    <div className="option-label">Wall Dimensions</div>  
                    <div className="widthAndHeight">
                      <div className="dimension-inputs">
                        <Form.Label>Width</Form.Label>
                        <Form.Control
                            id="wall-width-feet"
                            type='number'
                            onChange={handleFixedWidthFeetChange}
                            placeholder='feet'
                            value={fixedWallWidthFeet}
                        />
                        <Form.Control
                            id="wall-width-inches"
                            type='number'
                            onChange={handleFixedWidthInchesChange}
                            placeholder='inches'
                            value={fixedWallWidthInches}
                            min={-1}
                        />
                      </div>
                      <div className="dimension-inputs">
                        <Form.Label>Height</Form.Label>
                        <Form.Control
                            id="wall-height-feet"
                            type='number'
                            onChange={handleFixedHeightFeetChange}
                            placeholder='feet'
                            value={fixedWallHeightFeet}
                        />
                        <Form.Control
                            id="wall-height-inches"
                            type='number'
                            onChange={handleFixedHeightInchesChange}
                            placeholder='inches'
                            value={fixedWallHeightInches}
                            min={-1}
                        />
                      </div>
                    </div>
                    
                    <div className="option-label">Display Dimensions</div>
                    <div className="widthAndHeight">
                        <div className="dimension-inputs">
                            <Form.Label>Width</Form.Label>
                                <Form.Control
                                    id='display-width-feet'
                                    type='number'
                                    onChange={handleWidthFeetChange}
                                    placeholder='feet'
                                    value={wallWidthFeet}
                                />
                                <Form.Control
                                    id='display-width-inches'
                                    type='number'
                                    onChange={handleWidthInchesChange}
                                    placeholder='inches'
                                    value={wallWidthInches}
                                />
                        </div>
                        <div className="dimension-inputs">
                        <Form.Label>Height</Form.Label>
                                <Form.Control
                                    id='display-height-feet'
                                    type='number'
                                    onChange={handleHeightFeetChange}
                                    placeholder='feet'
                                    value={wallHeightFeet}
                                />
                                <Form.Control
                                    id='display-height-inches'
                                    type='number'
                                    onChange={handleHeightInchesChange}
                                    placeholder='inches'
                                    value={wallHeightInches}
                                    min={-1}
                                />
                        </div>
                    </div>

                    <div id='toggle-tabs'>
                      <div id='outdoorBtn' class='active-tab' onClick={()=>indoorOutdoorToggler()}>Outdoor<br />Products</div>
                      <div id='indoorBtn' onClick={()=>indoorOutdoorToggler()}>Indoor<br />Products</div>
                    </div>

                    <div id='module-select'>
                    <Form.Select
                        onChange={handleSetModule}
                        value={module?.value}
                    >
                        <option>Choose one...</option>
                        {indoorOutdoor == 'outdoor' &&
                            <>           
                            <option value='intelligentM2'>Intelligent-M2 [10-20mm]</option>
                            <option value='infinitySMD'>Infinity-SMD [2.9-10mm]</option>
                            <option value='infinityRGB'>Infinity-RGB [10-25mm]</option>
                            <option value='infinityGS'>Infinity-GS [16-25mm]</option>
                            <option value='envisionSMD'>Envision-SMD [6.667-10mm]</option>
                            <option value='infinityOneSMD'>Infinity-One SMD [9.525mm]</option>
                            </>
                        }
                        {indoorOutdoor == 'indoor' &&
                            <>
                            <option value='optPanel2'>Opt-Panel 2 [0.94-1.56mm]</option>
                            {/* <option value='optSlim'>Opt-Slim [2.6-4.8mm]</option> */}
                            <option value='opt_slim_2_6'>Opt-Slim [2.6mm]</option>
                            <option value='opt_slim_3_9'>Opt-Slim [3.9mm]</option>
                            <option value='opt_slim_4_8'>Opt-Slim [4.8mm]</option>
                            <option value='opt_slim_wp_1_9'>Opt-Slim WP[1.9mm]</option>
                            <option value='opt_slim_wp_2_5'>Opt-Slim WP[2.5mm]</option>
                            <option value='opt_slim_wp_2_6'>Opt-Slim WP[2.6mm]</option>
                            <option value='opt_slim_wp_3_9'>Opt-Slim WP[3.9mm]</option>
                            <option value='opt_slim_wp_4_8'>Opt-Slim WP[4.8mm]</option>
                            <option value='optPanel1'>Opt-Panel 1 [0.95-1.9mm]</option>
                            <option value='optPoster'>Opt-Poster [1.9-2.5mm]</option>
                            <option value='optTV'>Opt-TV [1.2-1.9mm]</option>
                            <option value='optWin'>Opt-Win [2.5-4.8mm]</option>
                            <option value='optCool'>Opt-Cool [2.5mm]</option>
                            <option value='optScore'>Opt-Score [6-10mm]</option>
                            </>
                        }
                        
                    </Form.Select>
                    {!module?.name.includes('Opt-Slim') &&
                      <Form.Select
                        onChange={(event) => setVariation(event.target.value)}
                        value={variation ? variation : 'Choose one...'}
                      >
                        <option>Choose one...</option>
                        {renderVariations(module)}
                      </Form.Select>
                    }
                    
		            {module?.ruleset.borderOptional &&
			          <button onClick={() => handleBorderToggle()}>Toggle Border</button>
		            }                    
                </div>
                    {indoorOutdoor == 'indoor' && variation != null &&
                     <>
                       <Form.Label className='option-label'>Presets</Form.Label>
                       <div id='indoor-presets'>
                        
                        {module?.ruleset.preset_1 &&
                         <div onClick={()=>setPreset({width: 1920, height: 1080})}>1920x1080</div>
                        }

                        {module?.ruleset.preset_2 &&
                         <div onClick={()=>setPreset({width: 2048, height: 1300})}>2k</div>
                        }

                        {module?.ruleset.preset_3 &&
                         <div onClick={()=>setPreset({width: 4096, height: 2160})}>4k</div>
                        }

                        {module?.ruleset.preset_4 && 
                         <div onClick={()=>setPreset({width: 7680, height: 4320})}>8k</div>
                        }
                        
                        {module?.ruleset.preset_4 == false && module?.ruleset.preset_3 == false && module?.ruleset.preset_2 == false && module?.ruleset.preset_1 == false &&
                          <div>No Available Presets</div>
                        } 
                        
                       </div>
                     </>
                    }

                    {module?.name.includes('Opt-Slim') &&
                      <>
                        <Form.Label className='option-label'>Presets</Form.Label>
                        <div id='indoor-presets'>
                          {module?.ruleset.preset_1 &&
                            <div onClick={() => setPreset({ width: 1920, height: 1080 })}>1920x1080</div>
                          }
                          {module?.ruleset.preset_2 &&
                            <div onClick={() => setPreset({ width: 2048, height: 1300 })}>2k</div>
                          }
                          {module?.ruleset.preset_3 &&
                            <div onClick={() => setPreset({ width: 4096, height: 2160 })}>4k</div>
                          }
                          {module?.ruleset.preset_4 &&
                            <div onClick={() => setPreset({ width: 7680, height: 4320 })}>8k</div>
                          }

                          {module?.ruleset.preset_4 == false && module?.ruleset.preset_3 == false && module?.ruleset.preset_2 == false && module?.ruleset.preset_1 == false &&
                            <div>No Available Presets</div>
                          }

                        </div>
                      </>
                    }



            
                    {indoorOutdoor == 'outdoor' && variation != null &&
                     <>
                       <Form.Label className='option-label'>Presets</Form.Label>
                       <div id='outdoor-presets'>
                        {module?.ruleset.preset_1 &&
                          <div onClick={()=>setPreset({width: 1920, height: 1080})}>1920x1080</div>
                        }
                        {module?.ruleset.preset_2 &&
                         <div onClick={()=>setPreset({width: 40, height: 10})}>10'H x 40'W</div>
                        }
                        {module?.ruleset.preset_3 &&
                         <div onClick={()=>setPreset({width: 40, height: 10.5})}>10.5'H x 40'W</div>
                        }
                        {module?.ruleset.preset_4 &&
                         <div onClick={()=>setPreset({width: 48, height:14})}>14'H x 48'W</div>
                        }
                        {module?.ruleset.preset_4 == false && module?.ruleset.preset_3 == false && module?.ruleset.preset_2 == false && module?.ruleset.preset_1 == false &&
                          <div>No Available Presets</div>
                        }
                       </div>
                     </>
                    } 
                </div>
      <p id="brand-reserved">©Optec Displays, Inc. All rights reserved. Not to scale. The images shown are for illustration purposes only. Subject to change without notice. </p>
            </div>
    )
}

export default Menu;
