import React from 'react'
import Table from 'react-bootstrap/Table';
import html2canvas from 'html2canvas';
import { Button } from 'react-bootstrap';
import businessLogo from '../images/business_logo.png'
import { faEnvelope, faXmark} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { roundToNearestHundreths } from '../lib/helpers';


const Details = (props) => {

  console.log("singleModHeight", props.singleModHeight);
  console.log("singleModWidth", props.singleModWidth);
  console.log("additionalModules", props.additionalModules);
  console.log("total mods", props.totalMods);

  console.log("modulesNeededForWidth", props.modulesNeededForWidth);
  console.log("modulesNeededForHeight", props.modulesNeededForHeight);


  let moduleHandle = props.module;
  let moduleName = moduleHandle?.name;
  let moduleVariation = moduleHandle?.variations[props.variation];
  
  function returnFixedDimensions(){
    if(props.fixedWallHeightFeet || props.fixedWallHeightInches || props.fixedWallWidthFeet || props.fixedWallWidthInches){
      return(
        <tr>
          <td>Fixed Wall Size</td>
          <td>
            {props.fixedWallWidthFeet ? props.fixedWallWidthFeet : 0} ft.
            {props.fixedWallWidthInches ? props.fixedWallWidthInches : 0} in.
            x
            {props.fixedWallHeightFeet ? props.fixedWallHeightFeet : 0} ft.
            {props.fixedWallHeightInches ? props.fixedWallHeightInches : 0} in.
          </td>
        </tr>
      )
    }else{
      return;
    }
  }

  function returnWpVariationCounts(){
    let rows = [];

    let fives = props.fivesNeededForWidth;
    let fours = props.foursNeededForWidth;
    let threes = props.threesNeededForWidth;
    let twos = props.twosNeededForWidth;
    let wpNoOfRows = props.wpNumberOfRows;

    console.log(fives, fours, threes, twos, wpNoOfRows)

    if(fives > 0){
      rows.push(<tr>
        <td>1x5 Mods Needed</td>
        <td>{fives * wpNoOfRows}</td>
      </tr>)
    }
    if(fours > 0){
      rows.push(<tr>
        <td>1x4 Mods Needed</td>
        <td>{fours * wpNoOfRows}</td>
      </tr>)
    }
    if(threes > 0){
      rows.push(<tr>
        <td>1x3 Mods Needed</td>
        <td>{threes * wpNoOfRows}</td>
      </tr>)
    }
    if(twos > 0){
      rows.push(<tr>
        <td>1x2 Mods Needed</td>
        <td>{twos * wpNoOfRows}</td>
      </tr>)
    }
  
    return rows
  }
  
  function returnModuleRow(){
    if (moduleVariation?.physical_dimensions_inches){
      return <td>{moduleName} - {moduleVariation?.name} ({moduleVariation?.physical_dimensions_inches.width} x {moduleVariation?.physical_dimensions_inches.height})</td>
    }

    if(moduleHandle?.physical_dimensions_inches){
      return <td>{moduleName} - {moduleVariation?.name} ({moduleHandle?.physical_dimensions_inches.width} x {moduleHandle?.physical_dimensions_inches.height})</td>
    }

    if (moduleName?.includes('Opt-Slim WP')){
      return <td>{moduleName}</td>
    }

    if (!moduleName?.includes('Opt-Slim') && !moduleHandle?.physical_dimensions_inches && !moduleVariation?.physical_dimensions_inches){
      return <td></td>
    }
  }

  function screenCapture() {
    html2canvas(document.body).then(function (canvas) {
      // Convert canvas to data URL
      let dataURL = canvas.toDataURL("image/png");

      // Create a new <a> element for the download
      let link = document.createElement("a");
      link.download = "screenshot.png"; // Filename for the downloaded image
      link.href = dataURL;

      // Programmatically trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    });
  }


  function closeSpecs() {
    document.getElementById("details-modal").style.display = "none";
  }

  return (
    <div id='details-modal'>
        
        <Button className='m-2' variant='light' onClick={closeSpecs}><FontAwesomeIcon icon={faXmark} /></Button>

        <div className="modal-wrap">
            <img src={businessLogo} width="400px" />
            <br />
            <Table striped bordered hover className="modal-content">
                <tbody>
                    <tr>
                      <td>Module</td>
                      {returnModuleRow()}
                    </tr>
                    {returnFixedDimensions()}
                    <tr>
                      <td>Display Size</td>
                      <td>
                        {roundToNearestHundreths(props.singleModWidth * props.modulesNeededForWidth / props.foot)}'
                        {roundToNearestHundreths(props.singleModWidth * props.modulesNeededForWidth % props.inch)}"
                          x
                        {props.additionalModules
                          ? roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight / props.foot) + (roundToNearestHundreths(props.module?.physical_dimensions_inches.height % props.inch)) / 2
                          : roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight / props.foot)}'
                        {props.additionalModules
                          ? roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight % props.inch) + (roundToNearestHundreths(props.module?.physical_dimensions_inches.height % props.inch)) / 2
                          : roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight % props.inch)}"
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Display Resolution
                      </td>
                      <td>
                        {props.resolution.width} x {props.additionalModules ? props.resolution.height + props.module?.variations[props.variation]?.resolution.height / 2 : props.resolution.height}                      </td>
                    </tr>
                    <tr>
                      <td>
                        Modules Needed
                      </td>
                      <td>
                        {props.totalMods}
                      </td>
                    </tr>
                    {returnWpVariationCounts()}
                </tbody>
            </Table>
        <Button variant='dark' onClick={()=>screenCapture()}>Download</Button>
        <br />
        <span style={{textAlign: 'center'}}>Contact us to build your custom display!</span>
        <br />
        <div id="contact-info">
          <div>
            <FontAwesomeIcon icon={faEnvelope} />&nbsp;<a href="mailto:dpratt@optec.com">Email</a> 
          </div>   
    </div> 
        </div>
    </div>
  )
}

export default Details;
