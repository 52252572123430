import React from 'react'
import { getMargins, roundDown, getObscureMargins } from '../lib/helpers';

const RenderIndoor = ({
  setPxHeight, setPxWidth, pxHeight, pxWidth,
  setSingleModHeight, setSingleModWidth, singleModHeight, singleModWidth,
  wallAndModuleInfo, setXMargins, setYMargins, setTotalMods,
  foot, inch, setModulesNeededForHeight, setModulesNeededForWidth, 
  modulesNeededForHeight, modulesNeededForWidth, setFlexDirection, variation,
  borderToggle, setInnerWidth,yMargins, innerWidth,
  
  setFivesResolution, setFoursResolution, setThreesResolution, setTwosResolution, setWpHeightResolution,
  setFivesNeededForWidth, setFoursNeededForWidth, setThreesNeededForWidth, setTwosNeededForWidth,

  additionalModules, setAdditionalModules

}) => {

  const { wallHeightFeet, wallHeightInches, 
          wallWidthFeet, wallWidthInches, module } = wallAndModuleInfo;

  let mods = [];

  function checkForHalfTiles() {
    console.log("checking for half tiles")
    if(!variation) return;
    let tempX = getMargins(pxHeight, singleModHeight);
    if (tempX * 2 >= singleModHeight / 2) {
      setAdditionalModules(modulesNeededForWidth);
      let newHeight = (modulesNeededForHeight * singleModHeight) + (singleModHeight / 2);
      let newMargins = getObscureMargins(pxHeight, newHeight)
      setXMargins(newMargins);
    } else {
      setXMargins(tempX);
      setAdditionalModules(0)
    }
    console.log("additional modules: ", additionalModules);
  } 


  setPxWidth(wallWidthFeet * foot + wallWidthInches * inch);
  setPxHeight(wallHeightFeet * foot + wallHeightInches * inch);

  let tempY = Math.ceil(getMargins(pxWidth, singleModWidth) * 10) / 10;
  
  if(tempY * 2 == singleModWidth){
    tempY = 0;
    let newWidth = singleModWidth * modulesNeededForWidth; 
    setInnerWidth(newWidth);
  }


  setXMargins(getMargins(pxHeight, singleModHeight));
  setYMargins(tempY);

  //this crazy if statement is here to set whether or not the 
  //display is horizontal or vertical and then handles the difference in
  //format that is required for each
  if(module && module != '' && !module?.name.includes('Opt-Slim')){
    let mod_dimensions;
    if(module.physical_dimensions_inches){
      mod_dimensions = module.physical_dimensions_inches;
    }else{
      variation = 0;
      mod_dimensions = module.variations[variation].physical_dimensions_inches;
    }

    setSingleModHeight(mod_dimensions.height * inch);
    setSingleModWidth(mod_dimensions.width * inch);

    console.log("Module: ", module);


    if (module.ruleset.tileHorizontal && module.ruleset.tileVertical == false) {
      console.log("Tiles horizontally");
      setModulesNeededForWidth(roundDown(pxWidth / (mod_dimensions.width * inch))); 
      setModulesNeededForHeight(
        roundDown(pxHeight / (mod_dimensions.height * inch)) >= 1 ? 1 : 0
      );
      setFlexDirection('row nowrap');
    } else if (module.ruleset.tileVertical && module.ruleset.tileHorizontal == false) {
        setModulesNeededForHeight(roundDown(pxHeight / (mod_dimensions.height * inch)));
        setModulesNeededForWidth(
        roundDown(pxWidth / (mod_dimensions.width * inch)) >= 1 ? 1 : 0
      );
      setFlexDirection('column nowrap');

    } else if (module.ruleset.tileHorizontal && module.ruleset.tileVertical) {
      console.log("Tiles both horizontally and vertically");
      console.log(`The ymargins are ${yMargins} and the innerWidth is ${innerWidth}`);
      setModulesNeededForHeight(roundDown(pxHeight / (mod_dimensions.height * inch)));
      setModulesNeededForWidth(roundDown(pxWidth / (mod_dimensions.width * inch)));
      setFlexDirection('row wrap');
      // this only applies to opt-slim
      if (module.name == 'Opt-Slim') {
        checkForHalfTiles();
      }
    } else {
      console.log("This module cannot be tiled in either direction");
    }
  }else{
    setSingleModHeight(0);
    setSingleModWidth(0);
    setModulesNeededForHeight(0);
    setModulesNeededForWidth(0);
  }
  //end crazy if statement

  //create an if statemenet specifically for Opt-Slim WP
  //and handle the rendering of that module accordingly
   if(module?.name.includes('Opt-Slim')){
      setFlexDirection('column wrap');    

      //here we have to redo the calculations for the fours and threes and twos needed for width
      //because the original numbers are determined by setting presets
      //and we need to calculate the actual number of modules needed for width
      //as each set has a different width

     let wpHeight = module.variations[0].physical_dimensions_inches.height * inch;
     let fivesWidth = module.variations[3].physical_dimensions_inches.width * inch;
     let foursWidth = module.variations[2].physical_dimensions_inches.width * inch;
     let threesWidth = module.variations[1].physical_dimensions_inches.width * inch;
     let twosWidth = module.variations[0].physical_dimensions_inches.width * inch;

     let fivesResolution = module.variations[3].resolution.width;
     let foursResolution = module.variations[2].resolution.width;
     let threesResolution = module.variations[1].resolution.width;
     let twosResolution = module.variations[0].resolution.width;
     let wpHeightResolution = module.variations[0].resolution.height;

     setFivesResolution(fivesResolution);
     setFoursResolution(foursResolution);
     setThreesResolution(threesResolution);
     setTwosResolution(twosResolution);
     setWpHeightResolution(wpHeightResolution);

     let fivesNeededForWidth = roundDown(pxWidth / fivesWidth);
    let remaining = pxWidth % (fivesWidth * fivesNeededForWidth);

     let foursNeededForWidth = roundDown(remaining / foursWidth);
    //  let remaining = pxWidth % (foursWidth * foursNeededForWidth);
    if(foursNeededForWidth > 0){
     remaining = remaining % (foursWidth * foursNeededForWidth);
    }
     let threesNeededForWidth = roundDown(remaining / threesWidth);
     if(threesNeededForWidth > 0){
       remaining = remaining % (threesWidth * threesNeededForWidth);
     }  

     let twosNeededForWidth = roundDown(remaining / twosWidth);
     if(twosNeededForWidth > 0){
      remaining = remaining % (twosWidth * twosNeededForWidth);
     }

    let wpNumberOfRows = roundDown(pxHeight / wpHeight);

    setFivesNeededForWidth(fivesNeededForWidth);
    setFoursNeededForWidth(foursNeededForWidth);
    setThreesNeededForWidth(threesNeededForWidth);
    setTwosNeededForWidth(twosNeededForWidth);


    setModulesNeededForWidth(fivesNeededForWidth + foursNeededForWidth + threesNeededForWidth + twosNeededForWidth);
    setModulesNeededForHeight(wpNumberOfRows);
  
      if(fivesNeededForWidth > 0){
        for (let i = 0; i < fivesNeededForWidth * wpNumberOfRows; i++) {
          mods.push(
            <div
              key={i}
              className={module.ruleset.seamless
                ? 'mod'
                : borderToggle
                  ? 'mod-not-seamless-with-extra-border'
                  : 'mod-not-seamless'}
              style={{
                width: fivesWidth,
                height: wpHeight,
                margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false
                  ? 'auto 0px'
                  : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false
                    ? '0px auto'
                    : '0px',
              }}>
              {i + 1}
            </div>
          );
        }
      }

      if(foursNeededForWidth > 0){
        for(let i = (fivesNeededForWidth * wpNumberOfRows); i < (fivesNeededForWidth * wpNumberOfRows) + (foursNeededForWidth * wpNumberOfRows); i++){
          mods.push(
            <div
              key={i}
              className={module.ruleset.seamless
                ? 'mod'
                : borderToggle
                  ? 'mod-not-seamless-with-extra-border'
                  : 'mod-not-seamless'}
              style={{
                width: foursWidth,
                height: wpHeight,
                margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false
                  ? 'auto 0px'
                  : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false
                    ? '0px auto'
                    : '0px',
              }}>
              {i + 1}
            </div>
          );
        }
      }

      if(threesNeededForWidth > 0){
        for (let i = (fivesNeededForWidth * wpNumberOfRows) + (foursNeededForWidth * wpNumberOfRows); i < (fivesNeededForWidth * wpNumberOfRows) + (foursNeededForWidth * wpNumberOfRows) + (threesNeededForWidth * wpNumberOfRows); i++){
          mods.push(
            <div
              key={i}
              className={module.ruleset.seamless
                ? 'mod'
                : borderToggle
                  ? 'mod-not-seamless-with-extra-border'
                  : 'mod-not-seamless'}
              style={{
                width: threesWidth,
                height: wpHeight,
                margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false
                  ? 'auto 0px'
                  : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false
                    ? '0px auto'
                    : '0px',
              }}>
              {i + 1}
            </div>
          );
        }
      }

      if(twosNeededForWidth > 0){
        for (let i = (fivesNeededForWidth * wpNumberOfRows) + (foursNeededForWidth * wpNumberOfRows) + (threesNeededForWidth * wpNumberOfRows); i < (fivesNeededForWidth * wpNumberOfRows) + (foursNeededForWidth * wpNumberOfRows) + (threesNeededForWidth * wpNumberOfRows) + (twosNeededForWidth  * wpNumberOfRows); i++){  
          mods.push(
            <div
              key={i}
              className={module.ruleset.seamless
                ? 'mod'
                : borderToggle
                  ? 'mod-not-seamless-with-extra-border'
                  : 'mod-not-seamless'}
              style={{
                width: twosWidth,
                height: wpHeight,
                margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false
                  ? 'auto 0px'
                  : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false
                    ? '0px auto'
                    : '0px',
              }}>
              {i + 1}
            </div>
          );
        }
      }




      //finally determin the remaining sectors of the wall that are not covered by modules
      //and deteremine the y and x margins for the wall
     
      let wpPhysicalWidth = fivesWidth * fivesNeededForWidth + foursWidth * foursNeededForWidth + threesWidth * threesNeededForWidth + twosWidth * twosNeededForWidth;

      let tempX = getMargins(pxHeight, wpHeight);
      let tempY = (pxWidth - wpPhysicalWidth) / 2;

      setXMargins(tempX);
      setYMargins(tempY);

   }


  let numberOfModulesNeededTotal = modulesNeededForWidth * modulesNeededForHeight;

  //maybe here we can add " && module.name != 'Opt-Slim WP' "  to the if statement
  //and then add a new if statement for Opt-Slim WP and handle that rendering accordingly
  if( numberOfModulesNeededTotal >= module?.ruleset.minimum && variation != undefined && variation != null && !module?.name.includes('Opt-Slim WP')){
    for (let i = 0; i < numberOfModulesNeededTotal; i++) {
      mods.push(
        <div 
          key={i} 
          className={module.ruleset.seamless 
                ? 'mod' 
                : borderToggle 
                    ? 'mod-not-seamless-with-extra-border'
                    : 'mod-not-seamless'} 
          style={{ 
              width: singleModWidth, 
              height: singleModHeight,
              margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false 
                    ? 'auto 0px' 
                    : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false 
                      ? '0px auto' 
                      : '0px',
              }}>
            {i + 1}
        </div>
      );
    }
  }

  for (let i = 0; i < additionalModules; i++) {
    mods.push(<div key={i + numberOfModulesNeededTotal} className="mod" style={{
      width: singleModWidth,
      height: singleModHeight / 2,
      margin: module.ruleset.tileHorizontal && module.ruleset.tileVertical == false ? 'auto 0px' : module.ruleset.tileVertical && module.ruleset.tileHorizontal == false ? '0px auto' : '0px',
    }}>{i + numberOfModulesNeededTotal + 1}</div>);
  }
  setTotalMods(numberOfModulesNeededTotal + additionalModules);
  return mods;
}

export default RenderIndoor;
