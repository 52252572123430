import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import man from '../images/man.svg'
import { getMargins, roundToNearestHundreths, wpHeight, wpWidth } from '../lib/helpers';
import RenderModules from './RenderModules'
import RenderIndoor from './RenderIndoor';
import Details from './Details';

const Main = (props) => {


	const [innerWidth, setInnerWidth] = useState(0);
	const [innerHeight, setInnerHeight] = useState(0);
	const [totalMods, setTotalMods] = useState(0);

	const [flexDirection, setFlexDirection] = useState('row wrap');
	const [factor, setFactor] = useState(0);

    useEffect(()=>{
      props.setFixedPxWidth(props.fixedWallWidthFeet * props.foot + props.fixedWallWidthInches * props.inch);
      props.setFixedPxHeight(props.fixedWallHeightFeet * props.foot + props.fixedWallHeightInches * props.inch);
    }, [props.fixedWallWidthFeet, props.fixedWallWidthInches, props.fixedWallHeightFeet, props.fixedWallHeightInches]);

    useEffect(()=>{
		setInnerHeight(props.pxHeight - (getMargins(props.pxHeight, props.singleModHeight) * 2));
		setInnerWidth(props.pxWidth - (getMargins(props.pxWidth, props.singleModWidth) * 2));
	}, [props.pxWidth, props.pxHeight, props.singleModWidth, props.singleModHeight]);


	useEffect(()=>{
		let { module, variation } = props;
		if (module != undefined && variation != null) {
			props.setResolution({
				height: module.variations[variation]?.resolution.height * props.modulesNeededForHeight,
				width: module.variations[variation]?.resolution.width * props.modulesNeededForWidth,
			});
		}else if (module?.name.includes('Opt-Slim') && variation == null) {
			props.setResolution({
				height: props.wpHeightResolution * props.modulesNeededForHeight,
				width: props.fivesResolution * props.fivesNeededForWidth + props.foursResolution * props.foursNeededForWidth + props.threesResolution * props.threesNeededForWidth + props.twosResolution * props.twosNeededForWidth,
			});
		}
	}, [props.module, props.variation, innerHeight, innerWidth, props.wpHeightResolution, props.wpNumberOfRows, props.fivesNeededForWidth, props.fivesResolution, props.foursResolution, props.foursNeededForWidth, props.threesResolution, props.threesNeededForWidth, props.twosResolution, props.twosNeededForWidth, props.modulesNeededForHeight]);


	const DisplayWall = () => {
		return(
			<div
				id="modules"
				ref={props.modulesRef}
				style={{
					width: props.pxWidth + 1,
                	height: props.pxHeight,
                    top: props.fixedPxHeight > 0 && props.fixedPxWidth > 0 ? '50%' : '59%',
					scale: `${props.boardScale}%`,
				}}
			>
				{props.indoorOutdoor == 'outdoor' ? (
					<div
						id="mod-wrapper"
						style={{
							backgroundColor: 'pink',
							width: innerWidth,
							height: innerHeight,
							marginTop: props.xMargins,
							marginBottom: props.xMargins,
							marginLeft: props.yMargins,
							marginRight: props.yMargins,
							flexFlow: flexDirection,
						}}
					>
						<RenderModules
							setPxHeight={props.setPxHeight}
							setPxWidth={props.setPxWidth}
							pxHeight={props.pxHeight}
							pxWidth={props.pxWidth}
							setSingleModHeight={props.setSingleModHeight}
							setSingleModWidth={props.setSingleModWidth}
							setFlexDirection={setFlexDirection}
							setXMargins={props.setXMargins}
							setYMargins={props.setYMargins}
							setTotalMods={setTotalMods}
							factor={factor}
							setFactor={setFactor}
							singleModHeight={props.singleModHeight}
							singleModWidth={props.singleModWidth}
							wallAndModuleInfo={props}
							inch={props.inch}
							foot={props.foot}
							setModulesNeededForHeight={props.setModulesNeededForHeight}
						    setModulesNeededForWidth={props.setModulesNeededForWidth}
							modulesNeededForHeight={props.modulesNeededForHeight}
							modulesNeededForWidth={props.modulesNeededForWidth}
							variation={props.variation}
							setInnerHeight={setInnerHeight}
							setInnerWidth={setInnerWidth}
						/>
					</div>
				) : (props.indoorOutdoor == 'indoor' ? (
					<div
						id="mod-wrapper"
						style={{
							backgroundColor: 'orange',
							width: innerWidth + 2,
							height: innerHeight + 2,
							marginTop: props.xMargins,
							marginBottom: props.xMargins,
							marginLeft: props.yMargins,
							marginRight: props.yMargins,
							flexFlow: flexDirection,
						}}>
						<RenderIndoor
							additionalModules={props.additionalModules}
							setAdditionalModules={props.setAdditionalModules}

							setPxHeight={props.setPxHeight}
							setPxWidth={props.setPxWidth}
							setWallWidthFeet={props.setWallWidthFeet}
							setWallWidthInches={props.setWallWidthInches}
							setWallHeightFeet={props.setWallHeightFeet}
							setWallHeightInches={props.setWallHeightInches}

							fivesNeededForWidth={props.fivesNeededForWidth}
							foursNeededForWidth={props.foursNeededForWidth}
							threesNeededForWidth={props.threesNeededForWidth}
							twosNeededForWidth={props.twosNeededForWidth}
							wpNumberOfRows={props.wpNumberOfRows}

							fivesResolution={props.fivesResolution}
							foursResolution={props.foursResolution}
							threesResolution={props.threesResolution}
							twosResolution={props.twosResolution}
							wpHeightResolution={props.wpHeightResolution}

							fivesWidth={props.fivesWidth}
							foursWidth={props.foursWidth}
							threesWidth={props.threesWidth}
							twosWidth={props.twosWidth}
							wpPhysicalHeight={props.wpPhysicalHeight}

							pxHeight={props.pxHeight}
							pxWidth={props.pxWidth}
							setSingleModHeight={props.setSingleModHeight}
							setSingleModWidth={props.setSingleModWidth}
							setFlexDirection={setFlexDirection}
							setXMargins={props.setXMargins}
							setYMargins={props.setYMargins}
							xMargins={props.xMargins}
							yMargins={props.yMargins}
							setTotalMods={setTotalMods}
							factor={factor}
							setModule={props.setModule}
							setFactor={setFactor}
							singleModHeight={props.singleModHeight}
							singleModWidth={props.singleModWidth}
							wallAndModuleInfo={props}
							variation={props.variation}
							inch={props.inch}
							foot={props.foot}
							setModulesNeededForHeight={props.setModulesNeededForHeight}
							setModulesNeededForWidth={props.setModulesNeededForWidth}
							modulesNeededForHeight={props.modulesNeededForHeight}
							borderToggle={props.borderToggle}
							modulesNeededForWidth={props.modulesNeededForWidth}
							setInnerHeight={setInnerHeight}
							setInnerWidth={setInnerWidth}

							setFivesResolution={props.setFivesResolution}
							setFoursResolution={props.setFoursResolution}
							setThreesResolution={props.setThreesResolution}
							setTwosResolution={props.setTwosResolution}
							setWpHeightResolution={props.setWpHeightResolution}

							setFivesNeededForWidth={props.setFivesNeededForWidth}
							setFoursNeededForWidth={props.setFoursNeededForWidth}
							setThreesNeededForWidth={props.setThreesNeededForWidth}
							setTwosNeededForWidth={props.setTwosNeededForWidth}
							setWpNumberOfRows={props.setWpNumberOfRows}
						/>
					</div>
				) : (null))}
			</div>
		);
	}


	const WrappedDisplayWall = () => {
		return(
			<div
				id='fixedWall'
				ref={props.fixedWallRef}
				style={{
					scale: `${props.fixedWallScale}%`,
					width: props.fixedPxWidth,
					height: props.fixedPxHeight
				}}>
				<DisplayWall />
			</div>
		);
	};

	return (
    <Container>
		<Details 

			foot={props.foot}
			inch={props.inch}

			modulesNeededForHeight={props.modulesNeededForHeight}
			modulesNeededForWidth={props.modulesNeededForWidth}

			additionalModules={props.additionalModules}

			fixedPxHeight={props.fixedPxHeight}
			fixedPxWidth={props.fixedPxWidth}
			fixedWallWidthFeet={props.fixedWallWidthFeet}
			fixedWallWidthInches={props.fixedWallWidthInches}
			fixedWallHeightFeet={props.fixedWallHeightFeet}
            fixedWallHeightInches={props.fixedWallHeightInches}

            totalMods={totalMods}
            resolution={props.resolution}

			pxHeight={props.pxHeight}
			pxWidth={props.pxWidth}
			singleModHeight={props.singleModHeight}
			singleModWidth={props.singleModWidth}
			xMargins={props.xMargins}
			yMargins={props.yMargins}
			
			wallWidthFeet={props.wallWidthFeet}
            wallWidthInches={props.wallWidthInches}
            wallHeightFeet={props.wallHeightFeet}
            wallHeightInches={props.wallHeightInches}
			module={props.module}
			variation={props.variation}

			fivesNeededForWidth={props.fivesNeededForWidth}
			foursNeededForWidth={props.foursNeededForWidth}
			threesNeededForWidth={props.threesNeededForWidth}
			twosNeededForWidth={props.twosNeededForWidth}
			wpNumberOfRows={props.wpNumberOfRows}

			fivesResolution={props.fivesResolution}
			foursResolution={props.foursResolution}
			threesResolution={props.threesResolution}
			twosResolution={props.twosResolution}
			wpHeightResolution={props.wpHeightResolution}

		/>
		<Row>
			<Col id='stats' sm={8}>
			<div class='stack-wrap'>
			<div id='wall-dims'>
				<div>Wall Width: 
                      {props.fixedWallWidthFeet ? props.fixedWallWidthFeet + "'" : '0\''}
                      {props.fixedWallWidthInches ? props.fixedWallWidthInches + "\"" : '0"'}
                </div>
				<div>Wall Height: 
                        {props.fixedWallHeightFeet ? props.fixedWallHeightFeet + "'": "0'"}
                        {props.fixedWallHeightInches ? props.fixedWallHeightInches + '"': '0"'}
                </div>
			</div>
			<div id='screen-dims'>
				{props.module?.name.includes('Opt-Slim') ? (
				<>
					<div>Display Width:
						{wpWidth(props.fivesNeededForWidth, props.foursNeededForWidth, props.threesNeededForWidth, props.twosNeededForWidth, props.fivesWidth, props.foursWidth, props.threesWidth, props.twosWidth, 'feet')}'
						{wpWidth(props.fivesNeededForWidth, props.foursNeededForWidth, props.threesNeededForWidth, props.twosNeededForWidth, props.fivesWidth, props.foursWidth, props.threesWidth, props.twosWidth, 'inches')}"
					</div>
					<div>Display Height:
						{wpHeight(props.wpNumberOfRows, props.wpPhysicalHeight, 'feet')}'
						{wpHeight(props.wpNumberOfRows, props.wpPhysicalHeight, 'inches')}"
					</div>
				</>	

				):(
				<>	
					<div>Display Width:
						{roundToNearestHundreths(props.singleModWidth * props.modulesNeededForWidth / props.foot)}'
						{roundToNearestHundreths(props.singleModWidth * props.modulesNeededForWidth % props.inch)}"
					</div>
					<div>Display Height:
						{props.additionalModules
						? roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight / props.foot) + (roundToNearestHundreths(props.module?.physical_dimensions_inches.height % props.inch)) / 2
						: roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight / props.foot)}'
						{props.additionalModules 
							? roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight % props.inch) + (roundToNearestHundreths(props.module?.physical_dimensions_inches.height % props.inch)) / 2
							: roundToNearestHundreths(props.singleModHeight * props.modulesNeededForHeight % props.inch)}"
					</div>
				</>
				)}

			</div>
			</div>
			<div>Resolution of screen: {props.resolution.width} x {props.additionalModules ? props.resolution.height + props.module?.variations[props.variation]?.resolution.height / 2 : props.resolution.height}</div>
			<div>Total modules needed: {totalMods}</div>
			</Col>
	  	</Row>
	  	<Row>
			<Col id='man-col' sm={4}>
			<img 
				id="forScaleMan" 
				src={man}
				style={{
				scale: `${props.manScale}%`,
				height: 980/4,
				width: 490/4,
				}}/>
			</Col>
			<Col id='scalingCol' sm={8}>
			{props.fixedPxHeight != 0 && props.fixedPxWidth != 0 && props.fixedWallRef != null ? (
				<WrappedDisplayWall />
			):(
				<DisplayWall />
			)}
			</Col>
    	</Row>
        {/* <div className="grid-container" style={{scale: `${props.manScale}%`}}></div> */}
	</Container>

    )
}

export default Main;
