import React, { useState, useEffect, useRef } from 'react'
import './App.css';

import Menu from './components/Menu.js'
import Main from './components/Main';
import indoorModules from './data/indoorModules';
import outdoorModules from './data/OutdoorModules';


function App() {
  //#region variables

  const inch = 2.5;//px
  const foot = 30;//px

  const [additionalModules, setAdditionalModules] = useState(0);

  const modulesRef = useRef(null);
  const fixedWallRef = useRef(null);

  //the selected module from the menu dropdown
  const [module, setModule] = useState(undefined);
  const [variation, setVariation] = useState(null);

  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  
  const [pxWidth, setPxWidth] = useState(0);
  const [pxHeight, setPxHeight] = useState(0);

  const [xMargins, setXMargins] = useState(0);
  const [yMargins, setYMargins] = useState(0);

  const [singleModWidth, setSingleModWidth] = useState(0);
  const [singleModHeight, setSingleModHeight] = useState(0);

  //the display's width and height in pixels
  //needs to be renamed to displayWidth and displayHeight
  const [wallWidthFeet, setWallWidthFeet] = useState('');
  const [wallHeightFeet, setWallHeightFeet] = useState('');
  const [wallWidthInches, setWallWidthInches] = useState('');
  const [wallHeightInches, setWallHeightInches] = useState('');

  const [modulesNeededForWidth, setModulesNeededForWidth] = useState(0);
  const [modulesNeededForHeight, setModulesNeededForHeight] = useState(0);

  //optional static wall variables
  const [fixedWallWidthFeet, setFixedWallWidthFeet] = useState('');
  const [fixedWallHeightFeet, setFixedWallHeightFeet] = useState('');
  const [fixedWallWidthInches, setFixedWallWidthInches] = useState('');
  const [fixedWallHeightInches, setFixedWallHeightInches] = useState('');

  const [fixedPxWidth, setFixedPxWidth] = useState(0);
  const [fixedPxHeight, setFixedPxHeight] = useState(0);

  //values to make comparisons with so we can actually calculate whether
  //or not the modules area is getting bigger or smaller
  const [newSizes, setNewSizes] = useState({width: 0, height: 0});
  const [oldSizes, setOldSizes] = useState({width: 0, height: 0});

  const [newFixedSizes, setNewFixedSizes] = useState({width: 0, height: 0});
  const [oldFixedSizes, setOldFixedSizes] = useState({width: 0, height: 0});

  //scale values that will be applied for the man and screens/walls/modules
  const [boardScale, setBoardScale] = useState(100);
  const [fixedWallScale, setFixedWallScale] = useState(100);
  const [manScale, setManScale] = useState(100);

  //for the toggle of indoor and outdoor
  const [indoorOutdoor, setIndoorOutdoor] = useState('outdoor');

  //only for opt-win panels
  const [borderToggle, setBorderToggle] = useState(false);

  //opt slim wp variables
  const [remainingWpMargin, setRemainingWpMargin] = useState(0);

  const [fivesResolution, setFivesResolution] = useState(0);
  const [foursResolution, setFoursResolution] = useState(0);
  const [threesResolution, setThreesResolution] = useState(0);
  const [twosResolution, setTwosResolution] = useState(0);

  const [fivesWidth, setFivesWidth] = useState(0);
  const [foursWidth, setFoursWidth] = useState(0);
  const [threesWidth, setThreesWidth] = useState(0);
  const [twosWidth, setTwosWidth] = useState(0);

  const [fivesNeededForWidth, setFivesNeededForWidth] = useState(0);
  const [foursNeededForWidth, setFoursNeededForWidth] = useState(0);
  const [threesNeededForWidth, setThreesNeededForWidth] = useState(0);
  const [twosNeededForWidth, setTwosNeededForWidth] = useState(0);
  const [wpNumberOfRows, setWpNumberOfRows] = useState(0);

  const [wpPhysicalHeight, setWpPhysicalHeight] = useState(0);
  const [wpHeightResolution, setWpHeightResolution] = useState(0);

  //#endregion variables

  //#region handleFunctions

  const handleWidthFeetChange = (event) => {
		if(event.target.value < 0){
			setWallWidthFeet(0);
		}else{
      if(event.target.value.length == 4)return;
			setWallWidthFeet(event.target.value);
		}
	};
  
  const handleHeightFeetChange = (event) => {
    //if any part of the event.target.value is a letter or special character, we need to remove it
    let result = event.target.value.replace(/[^0-9]/g, '');
    if(result < 0){
			setWallHeightFeet(0)
		}else{
      if (result.length >= 4) return;
			setWallHeightFeet(result);
		}
	};

  const handleWidthInchesChange = (event) => {
    if(event.target.value ==  12){
      setWallWidthInches(0);
      setWallWidthFeet(Number(wallWidthFeet) + 1);
    } else if(event.target.value < 0  && wallWidthFeet > 0){
      setWallWidthInches(11);
      setWallWidthFeet(wallWidthFeet - 1);
    }else if(event.target.value < 0 && wallWidthFeet == 0){
	  setWallWidthInches(0);
	}else{
      if(event.target.value.length > 3) return;
      setWallWidthInches(event.target.value)
    }
  };

  const handleHeightInchesChange = (event) => {
    if(event.target.value == 12){
      setWallHeightInches(0);
      setWallHeightFeet(Number(wallHeightFeet) + 1);
    } else if(event.target.value < 0 && wallHeightFeet !== 0){ 
	  setWallHeightInches(11);
      setWallHeightFeet(wallHeightFeet - 1);
    }else if(event.target.value < 0 && wallHeightFeet == 0){
			setWallHeightInches(0)
    }else{
      if (event.target.value.length == 3) return;
      setWallHeightInches(event.target.value)  
    }
  };

  const handleFixedWidthFeetChange = (event) => {
    if(event.target.value < 0){
      setFixedWallWidthFeet(0);
    }else{
      if (event.target.value.length == 4) return;
      setFixedWallWidthFeet(event.target.value);
    }
  };

  const handleFixedHeightFeetChange = (event) => {
    if(event.target.value < 0){
      setFixedWallHeightFeet(0);
    }else{
      if (event.target.value.length == 4) return;
      setFixedWallHeightFeet(event.target.value);
    }
  };

  const handleFixedWidthInchesChange = (event) => {
    if(event.target.value == 12){
      setFixedWallWidthInches(0);
      setFixedWallWidthFeet(Number(fixedWallWidthFeet) + 1);
    } else if(event.target.value < 0 && fixedWallWidthFeet > 0){
      setFixedWallWidthInches(11);
      setFixedWallWidthFeet(fixedWallWidthFeet - 1);
    }else if(event.target.value < 0 && fixedWallWidthFeet == 0){
      setFixedWallWidthInches(0);
    }else{
      if (event.target.value.length == 3) return;
      setFixedWallWidthInches(event.target.value)
    }
  };

  const handleFixedHeightInchesChange = (event) => {
    if(event.target.value == 12){
      setFixedWallHeightInches(0);
      setFixedWallHeightFeet(Number(fixedWallHeightFeet) + 1);
    } else if(event.target.value < 0 && fixedWallHeightFeet > 0){
      setFixedWallHeightInches(11);
      setFixedWallHeightFeet(fixedWallHeightFeet - 1);
    }else if(event.target.value < 0 && fixedWallHeightFeet == 0){
      setFixedWallHeightInches(0);
    }else{
      if (event.target.value.length == 3) return;
      setFixedWallHeightInches(event.target.value)
    }
  };

  


  const indoorOutdoorToggler = () => {
    let indoorBtn = document.getElementById('indoorBtn');
    let outdoorBtn = document.getElementById('outdoorBtn');

    indoorOutdoor == "outdoor" ? setIndoorOutdoor('indoor') : setIndoorOutdoor('outdoor');

    setWallWidthFeet('');
    setWallWidthInches('');
    setWallHeightFeet('');
    setWallHeightInches('');
    setResolution({width: 0, height: 0});
    indoorBtn.classList.toggle('active-tab');
    outdoorBtn.classList.toggle('active-tab');
    setModule(undefined);
    setVariation(null);
    setModulesNeededForWidth(0);
    setModulesNeededForHeight(0);
  }


  const handleSetModule = (event) => {
    let mod = event.target.value;
    setModulesNeededForWidth(0);
    setModulesNeededForHeight(0);
    setAdditionalModules(0)

    if(!mod.includes('Opt-Slim')){
      setFivesNeededForWidth(0);
      setFoursNeededForWidth(0);
      setThreesNeededForWidth(0);
      setTwosNeededForWidth(0);
    }else{
      setFivesResolution(indoorModules[mod].resolution.width);
      setFoursResolution(indoorModules[mod].resolution.width);
      setThreesResolution(indoorModules[mod].resolution.width);
      setTwosResolution(indoorModules[mod].resolution.width);
      setWpHeightResolution(indoorModules[mod].resolution.height);
      setWpPhysicalHeight(indoorModules[mod].physical_dimensions_inches.height);
      setFivesWidth(indoorModules[mod].physical_dimensions_inches.width);
      setFoursWidth(indoorModules[mod].physical_dimensions_inches.width);
      setThreesWidth(indoorModules[mod].physical_dimensions_inches.width);
      setTwosWidth(indoorModules[mod].physical_dimensions_inches.width);

    }

    setVariation(null);
    if(indoorOutdoor == 'outdoor'){
      setModule(outdoorModules[mod]);
    }else{
      setModule(indoorModules[mod]);
    }
  }

  

  const handleScaling = (scalingDirection, area) => {
    if(area == 'display'){
      if (scalingDirection == 'up') {
        let bScale = boardScale - 5;
        setBoardScale(bScale);
        setManScale(bScale);
      } else if (scalingDirection == 'down') {
        let bScale = boardScale + 5;
        setBoardScale(bScale);
        setManScale(bScale);
      }
    }else{
      if(scalingDirection == 'up'){
        let fwScale = fixedWallScale - 5;
        setFixedWallScale(fwScale);
        setManScale(fwScale);
      }else if(scalingDirection == 'down'){
        let fwScale = fixedWallScale + 5;
        setFixedWallScale(fwScale);
        setManScale(fwScale);
      }
    }
  }



  const handleBorderToggle = () => {
	  setBorderToggle(!borderToggle);
  }


  //#endregion handleFunctions

  function checkModulesBoundsTooLarge(modulesBounds) {
    return modulesBounds.bottom >= window.innerHeight || modulesBounds.right >= window.innerWidth;
  }

  function checkModulesBoundsShrinking(){
    return (wallHeightFeet + wallHeightInches) + ((wallHeightFeet + wallHeightInches) * .05) < window.innerHeight && (wallWidthFeet + wallWidthInches) + ((wallWidthFeet + wallWidthInches) * .05) < window.innerWidth;
  }


  function scaleArea(newSizes, oldSizes, area, currentScale, pxWidth, pxHeight){
    
    let bounds;

    if(area == 'display'){
      bounds = modulesRef.current.getBoundingClientRect();
    }else if(fixedWallRef.current != null){
      bounds = fixedWallRef.current.getBoundingClientRect();
    }else{
      return;
    }

    const proposedBounds = {
      right: bounds.x + pxWidth * ((currentScale + 5) / 100) + 5,//we use +5 on the end of these to make up for somecases where
      bottom: bounds.y + pxHeight * ((currentScale + 5) / 100) + 5//a loop of scaling will occur, do not remove these +5's
    }

  
      if(newSizes.width + 2.5 == oldSizes.width || 
         newSizes.width - 2.5 == oldSizes.width || 
         newSizes.height + 2.5 == oldSizes.height || 
         newSizes.height - 2.5 == oldSizes.height ||
         newSizes.width + 30 == oldSizes.width ||
         newSizes.width - 30 == oldSizes.width ||
         newSizes.height + 30 == oldSizes.height ||
         newSizes.height - 30 == oldSizes.height){
          //handle incremental scaling
         if(oldSizes.width > newSizes.width || oldSizes.height > newSizes.height){
           if (currentScale < 100 && checkModulesBoundsShrinking()){
             if(checkModulesBoundsTooLarge(proposedBounds)){
               return
             }else{
                 handleScaling('down', area);
             }
           }
         } else if (oldSizes.width < newSizes.width || oldSizes.height < newSizes.height){
           if(checkModulesBoundsTooLarge(bounds) && currentScale != 5 ) {
               handleScaling('up', area);
            }
         }   
      }else{
        
        if(checkModulesBoundsTooLarge(bounds) && currentScale != 5){
            const max_width = window.innerWidth - bounds.x;
            const max_height = window.innerHeight - bounds.y;
         
            if(max_width <= newSizes.width || max_height <= newSizes.height && currentScale != 5){  
             console.log('scaling up...');
              handleScaling('up', area);
            }
        }else if(currentScale != 100 && bounds.right <= window.innerWidth && bounds.bottom <= window.innerHeight){
          if(checkModulesBoundsTooLarge(proposedBounds)){
            return
          }else{
            console.log('scaling down...');
            handleScaling('down', area);
          }
        }
      }
      if(area == 'display'){
        setOldSizes({
          width: newSizes.width,
          height: newSizes.height
        });
      }else{
       setOldFixedSizes({
         width: newSizes.width,
         height: newSizes.height
        });
      }
  }



//#region useEffects
  useEffect(() => {
    setNewSizes({ 
      width: wallWidthFeet * 30 + wallWidthInches * 2.5,  
      height: wallHeightFeet * 30 + wallHeightInches * 2.5
    });
  }, [wallWidthInches, wallWidthFeet, wallHeightFeet, wallHeightInches])

  useEffect(()=>{
    setNewFixedSizes({
      width: fixedWallWidthFeet * 30 + fixedWallWidthInches * 2.5,
      height: fixedWallHeightFeet * 30 + fixedWallHeightInches * 2.5
    });
  }, [fixedWallWidthFeet, fixedWallWidthInches, fixedWallHeightFeet, fixedWallHeightInches])
  
  useEffect(()=>{
    if(fixedPxHeight == 0 || fixedPxWidth == 0){
      scaleArea(newSizes, oldSizes, 'display', boardScale, pxWidth, pxHeight);
    }
    
   }, [boardScale, newSizes.width, newSizes.height])


  useEffect(()=>{
    scaleArea(newFixedSizes, oldFixedSizes, 'fixed', fixedWallScale, fixedPxWidth, fixedPxHeight);
    if(fixedPxHeight !=0 || fixedPxWidth != 0){
      scaleArea(newSizes, oldSizes, 'display', boardScale, pxWidth, pxHeight);
    }
  }, [fixedWallScale, newFixedSizes.width, newFixedSizes.height ])

  useEffect(()=>{
    if(fixedPxHeight > 0 && fixedPxWidth > 0){
      setBoardScale(100);
    }
  },[fixedPxHeight, fixedPxWidth])

  useEffect(()=>{
    console.log(module)
    if(module?.name.includes("Opt-Slim")){
      let wpHeight = module.variations[0].physical_dimensions_inches.height * inch;
      let fivesWidth = module.variations[3].physical_dimensions_inches.width * inch;
      let foursWidth = module.variations[2].physical_dimensions_inches.width * inch;
      let threesWidth = module.variations[1].physical_dimensions_inches.width * inch;
      let twosWidth = module.variations[0].physical_dimensions_inches.width * inch;

      let fivesNeeded = Math.floor(pxWidth / fivesWidth);

      let remaining = 0;

      if (fivesNeeded > 0) {
        remaining = pxWidth % (fivesWidth * fivesNeeded);
      }

      let foursNeeded = Math.floor(remaining / foursResolution);

      if (foursNeeded > 0) {
        if (fivesNeeded > 0) {
          remaining = remaining % (foursWidth * foursNeeded);
        } else {
          remaining = pxWidth % (foursWidth * foursNeeded);
        }
      }

      
      let threesNeeded = Math.floor(pxWidth / threesWidth);
      
      if (threesNeeded > 0) {
        if (foursNeeded > 0 || fivesNeeded > 0) {
          remaining = remaining % (threesWidth * threesNeeded);
        } else {
          remaining = pxWidth % (threesWidth * threesNeeded);
        }
      }
      
      let twosNeeded = Math.floor(pxWidth / twosWidth);
      
      if (twosNeeded > 0) {
        if (foursNeeded > 0 || threesNeeded > 0 || fivesNeeded > 0) {
          remaining = remaining % (twosWidth * twosNeeded);
        } else {
          remaining = pxWidth % (twosWidth * twosNeeded);
        }
      }
      
      let wpNumberOfRows = Math.floor(pxHeight / wpHeight);
    

      setFivesWidth(fivesWidth);
      setFoursWidth(foursWidth);
      setThreesWidth(threesWidth);
      setTwosWidth(twosWidth);
      
      setWpNumberOfRows(wpNumberOfRows);
      setWpPhysicalHeight(wpHeight);

      setFivesNeededForWidth(fivesNeeded);
      setFoursNeededForWidth(foursNeeded);
      setThreesNeededForWidth(threesNeeded);
      setTwosNeededForWidth(twosNeeded);
    }
  }, [module, pxWidth, pxHeight])

  //#endregion useEffects



  return (
    <div id="App">
      <Menu
        inch={inch}
        foot={foot}

        additionalModules={additionalModules}

        handleSetModule={handleSetModule}
        module={module}

        setPxHeight={setPxHeight}
        setPxWidth={setPxWidth}

        setFixedWallWidthFeet={setFixedWallWidthFeet}
        setFixedWallWidthInches={setFixedWallWidthInches}
        setFixedWallHeightFeet={setFixedWallHeightFeet}
        setFixedWallHeightInches={setFixedWallHeightInches}

        setRemainingWpMargin={setRemainingWpMargin}
        remainingWpMargin={remainingWpMargin}

        indoorOutdoorToggler={indoorOutdoorToggler}
        indoorOutdoor={indoorOutdoor}

        setModulesNeededForWidth={setModulesNeededForWidth}
        setModulesNeededForHeight={setModulesNeededForHeight}
        modulesNeededForWidth={modulesNeededForWidth}
        modulesNeededForHeight={modulesNeededForHeight}

        setSingleModWidth={setSingleModWidth}
        setSingleModHeight={setSingleModHeight}
        singleModWidth={singleModWidth}
        singleModHeight={singleModHeight}

        setBoardScale={setBoardScale}

        setVariation={setVariation}
        variation={variation}

        setXMargins={setXMargins}
        setYMargins={setYMargins}
        yMargins={yMargins}
        xMargins={xMargins}

        pxWidth={pxWidth}
        pxHeight={pxHeight}

        handleWidthFeetChange={handleWidthFeetChange}
        handleHeightFeetChange={handleHeightFeetChange}
        handleWidthInchesChange={handleWidthInchesChange}
        handleHeightInchesChange={handleHeightInchesChange}

        handleFixedWidthFeetChange={handleFixedWidthFeetChange}
        handleFixedHeightFeetChange={handleFixedHeightFeetChange}
        handleFixedWidthInchesChange={handleFixedWidthInchesChange}
        handleFixedHeightInchesChange={handleFixedHeightInchesChange}

	      handleBorderToggle={handleBorderToggle} 
	  
        setWallWidthFeet={setWallWidthFeet}
        setWallHeightFeet={setWallHeightFeet}
        setWallWidthInches={setWallWidthInches}
        setWallHeightInches={setWallHeightInches}


        wallWidthFeet={wallWidthFeet}
        wallHeightFeet={wallHeightFeet}
        wallWidthInches={wallWidthInches}
        wallHeightInches={wallHeightInches}
      
        fixedWallWidthFeet={fixedWallWidthFeet}
        fixedWallHeightFeet={fixedWallHeightFeet}
        fixedWallWidthInches={fixedWallWidthInches}
        fixedWallHeightInches={fixedWallHeightInches}

        setFivesNeededForWidth={setFivesNeededForWidth}
        setFoursNeededForWidth={setFoursNeededForWidth}
        setThreesNeededForWidth={setThreesNeededForWidth}
        setTwosNeededForWidth={setTwosNeededForWidth}
        setWpNumberOfRows={setWpNumberOfRows}

        setWpPhysicalHeight={setWpPhysicalHeight}

        setFivesResolution={setFivesResolution}
        setFoursResolution={setFoursResolution}
        setThreesResolution={setThreesResolution}
        setTwosResolution={setTwosResolution}
        setWpHeightResolution={setWpHeightResolution}
        
        setFivesWidth={setFivesWidth}
        setFoursWidth={setFoursWidth}
        setThreesWidth={setThreesWidth}
        setTwosWidth={setTwosWidth}

      />
      <Main
        inch={inch}
        foot={foot}

        additionalModules={additionalModules}
        setAdditionalModules={setAdditionalModules}

        setFivesNeededForWidth={setFivesNeededForWidth}
        setFoursNeededForWidth={setFoursNeededForWidth}
        setThreesNeededForWidth={setThreesNeededForWidth}
        setTwosNeededForWidth={setTwosNeededForWidth}
        setWpNumberOfRows={setWpNumberOfRows}

        setFivesResolution={setFivesResolution}
        setFoursResolution={setFoursResolution}
        setThreesResolution={setThreesResolution}
        setTwosResolution={setTwosResolution}
        setWpHeightResolution={setWpHeightResolution}

        fivesNeededForWidth={fivesNeededForWidth}
        foursNeededForWidth={foursNeededForWidth}
        threesNeededForWidth={threesNeededForWidth}
        twosNeededForWidth={twosNeededForWidth}
        wpNumberOfRows={wpNumberOfRows}

        fivesResolution={fivesResolution}
        foursResolution={foursResolution}
        threesResolution={threesResolution}
        twosResolution={twosResolution}
        wpHeightResolution={wpHeightResolution}
        wpPhysicalHeight={wpPhysicalHeight}

        fivesWidth={fivesWidth}
        foursWidth={foursWidth}
        threesWidth={threesWidth}
        twosWidth={twosWidth}

        wallWidthFeet={wallWidthFeet}
        wallHeightFeet={wallHeightFeet}
        wallWidthInches={wallWidthInches}
        wallHeightInches={wallHeightInches}

        fixedWallWidthFeet={fixedWallWidthFeet}
        fixedWallHeightFeet={fixedWallHeightFeet}
        fixedWallWidthInches={fixedWallWidthInches}
        fixedWallHeightInches={fixedWallHeightInches}

        module={module}

        modulesNeededForWidth={modulesNeededForWidth}
        modulesNeededForHeight={modulesNeededForHeight}

        setModulesNeededForWidth={setModulesNeededForWidth}
        setModulesNeededForHeight={setModulesNeededForHeight}

        setResolution={setResolution}
        resolution={resolution}

        pxHeight={pxHeight}
        pxWidth={pxWidth}
        setPxHeight={setPxHeight}
        setPxWidth={setPxWidth}
        
        fixedPxHeight={fixedPxHeight}
        fixedPxWidth={fixedPxWidth}
        setFixedPxWidth={setFixedPxWidth}
        setFixedPxHeight={setFixedPxHeight}

        setXMargins={setXMargins}
        setYMargins={setYMargins}
        yMargins={yMargins}
        xMargins={xMargins}
        
        singleModWidth={singleModWidth}
        singleModHeight={singleModHeight}
        
        setSingleModWidth={setSingleModWidth}
        setSingleModHeight={setSingleModHeight}
        
        modulesRef={modulesRef}
        fixedWallRef={fixedWallRef}

        fixedWallScale={fixedWallScale}
        boardScale={boardScale}
        manScale={manScale}

        borderToggle={borderToggle}
        
        indoorOutdoor={indoorOutdoor}
        variation={variation}
      /> 
    </div>

  );
}

export default App;
