export function getMargins(wallDimension, moduleDimension) {
    return (wallDimension % moduleDimension) / 2;
}

export function roundDown(x) {
    return Math.floor(x);
}

export function getObscureMargins(wallDimension, correspondingInnerDimension) {
    return (wallDimension - correspondingInnerDimension) / 2
}

export function checkModulesBounds(modulesBounds) {
    return modulesBounds.bottom >= window.innerHeight || modulesBounds.right >= window.innerWidth;
}

export function roundToNearestHundreths(num) {
    return Math.round((100 * num) / 100);
}



export function roundUPtoNearestHundreth(num){
    return Math.ceil((num * 100) / 100);
}

export function wpWidth(fivesNeeded, foursNeeded, threesNeeded, twosNeeded, fivesWidth, foursWidth, threesWidth, twosWidth, feetOrInches){
    if(feetOrInches == 'feet'){
        return roundToNearestHundreths((fivesNeeded * fivesWidth + foursNeeded * foursWidth + threesNeeded * threesWidth + twosNeeded * twosWidth) / 30);
    }else if(feetOrInches == 'inches'){
        return roundToNearestHundreths((fivesNeeded * fivesWidth + foursNeeded * foursWidth + threesNeeded * threesWidth + twosNeeded * twosWidth) % 2.5);
    }
}

export function wpHeight(wpRows, wpPhysicalHeight, feetOrInches){
    if(feetOrInches == 'feet'){
        return roundToNearestHundreths(wpRows * wpPhysicalHeight / 30);
    }else if(feetOrInches == 'inches'){
        return roundToNearestHundreths(wpRows * wpPhysicalHeight % 2.5);
    }
}