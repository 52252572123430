const indoorModules = {
    optPanel2: {
        name: 'Opt-Panel 2', 
        value: 'optPanel2',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '0.94mm',
                pixel_pitch: 0.94,
                resolution: {
                    width: 640,
                    height: 360
                }
            },
            {
                id: 1,
                name: '1.25mm',
                pixel_pitch: 1.25,
                resolution: {
                    height: 270,
                    width: 480
                }
            },
            {
                id: 2,
                name: '1.56mm',
                pixel_pitch: 1.56,
                resolution: {
                    height: 216,
                    width: 384
                }
            },
            {
                id: 3,
                name: '1.88mm',
                pixel_pitch: 1.88,
                resolution: {
                  width: 320,
                  height: 180
                }
            },
        ],
        physical_dimensions_inches: {
            height: 13.28,
            width: 23.62
        }
    },
    // Original Opt-Slim
    optSlim: {
        name: 'Opt-Slim',
        value: 'optSlim',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: true,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 384,
                    width: 192
                }
            },
            {
                id: 1, 
                name: '3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 256,
                    width: 128
                }
            },
            {
                id: 2,
                name: '4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 208,
                    width: 104
                }
            }
        ],
        physical_dimensions_inches: {
            height: 39.4,
            width: 19.68,
        }
    },
    // End Original Opt-Slim

    // New Opt-Slim
    opt_slim_2_6:{
        name: 'Opt-Slim 2.6mm',
        value: 'opt_slim_2_6',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 384,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 576,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 2,
                name: '1x4 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 768,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 3,
                name: '1x5 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 960,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            }
        ],
    },

    opt_slim_3_9:{
        name: 'Opt-Slim 3.9mm',
        value: 'opt_slim_3_9',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 256,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 384,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 2,
                name: '1x4 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 512,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 3,
                name: '1x5 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 640,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            }
        ],
    },

    opt_slim_4_8:{
        name: 'Opt-Slim 4.8mm',
        value: 'opt_slim_4_8',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 208,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 312,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 2,
                name: '1x4 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 416,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            },
            {
                id: 3,
                name: '1x5 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 520,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 39.4,
                    width: 19.68,
                },
            }
        ],
    },
    // End New Opt-Slim

    opt_slim_wp_1_9:{
        name: 'Opt-Slim WP 1.9mm',
        value: 'opt_slim_wp_1_9',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 192,
                    width: 384
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 192,
                    width: 576
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 29.52,
                },
            },
            {
                id: 2,
                name: '1x4 1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 192,
                    width: 768
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 39.36,
                },
            },
            {
                id: 3,
                name: '1x5 1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 192,
                    width: 960
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 49.2,
                },
            }
        ],
    },
    opt_slim_wp_2_5:{
        name: 'Opt-Slim WP 2.5mm',
        value: 'opt_slim_wp_2_5',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 96,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 96,
                    width: 288
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 29.52,
                },
            },
            {
                id: 2,
                name: '1x4 2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 96,
                    width: 384
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 39.36,
                },
            },
            {
                id: 3,
                name: '1x5 2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 96,
                    width: 480
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 49.2,
                }, 
            }
        ],
    },

    opt_slim_wp_2_6:{
        name: 'Opt-Slim WP 2.6mm',
        value: 'opt_slim_wp_2_6',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 96,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 96,
                    width: 288
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 29.52,
                },
            },
            {
                id: 2,
                name: '1x4 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 96,
                    width: 384
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 39.36,
                },
        
            },
            {
                id: 3,
                name: '1x5 2.6mm',
                pixel_pitch: 2.6,
                resolution: {
                    height: 96,
                    width: 480
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 49.2,
                },
            }
        ],
    },
    opt_slim_wp_3_9:{
        name: 'Opt-Slim WP 3.9mm',
        value: 'opt_slim_wp_3_9',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 64,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 64,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 29.52,
                },
            },
            {
                id: 2,
                name: '1x4 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 64,
                    width: 256
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 39.36,
                },
            },
            {
                id: 3,
                name: '1x5 3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 64,
                    width: 320
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 49.2,
                },
            }
        ],
    },
    opt_slim_wp_4_8:{
        name: 'Opt-Slim WP 4.8mm',
        value: 'opt_slim_wp_4_8',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1x2 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 52,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 19.68,
                },
            },
            {
                id: 1,
                name: '1x3 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 52,
                    width: 156
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 29.52,
                },
            },
            {
                id: 2,
                name: '1x4 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 52,
                    width: 208
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 39.36,
                },
            },
            {
                id: 3,
                name: '1x5 4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 52,
                    width: 260
                },
                physical_dimensions_inches: {
                    height: 9.84,
                    width: 49.2,
                },
            },
        ],
    },
    optPanel1: {
        name: 'Opt-Panel 1',
        value: 'optPanel1',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true,
            canSplitInHalf: false,
            seamless: true,
            minimum: 1,
            borderOptional: false,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '0.95mm',
                pixel_pitch: 0.95,
                resolution: {
                    height: 360,
                    width: 640,
                }
            },
            {
                id: 1,
                name: '1.27mm',
                pixel_pitch: 1.27,
                resolution: {
                    height: 270,
                    width: 480,
                }
            },
            {
                id: 2,
                name: '1.58mm',
                pixel_pitch: 1.58,
                resolution: {
                    height: 216,
                    width: 384,
                }
            },
            {
                id: 3,
                name: '1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 180,
                    width: 320,
                }
            },
        ],
        physical_dimensions_inches: {
            height: 13.5,
            width: 24
        }
    },
    optPoster: {
        name: 'Opt-Poster',
        value: 'optPoster', 
        ruleset: {
            tileHorizontal: true,
            tileVertical: true,
            canSplitInHalf: false,
            seamless: false,
            minimum: 1,
            borderOptional: false,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '1.9mm',
                pixel_pitch: 1.9,
                resolution: {
                    height: 1026,
                    width: 305
                }
            },
            {
                id: 1,
                name: '2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 756,
                    width: 224
                }
            }
        ],
        physical_dimensions_inches: {
            height: 77.3,
            width: 23.44
        }
    },
    optTV: {
        name: 'Opt-TV',
        value: 'optTV',
        ruleset: {
            tileHorizontal: true,
            tileVertical: false,
            canSplitInHalf: false,
            seamless: false,
            minimum: 1,
            borderOptional: false,
            preset_1: true,
            preset_2: false,
            preset_3: false,
            preset_4: false
        },
        variations: [
            {
                id: 0,
                name: '1.2mm (110" Diag.)',
                pixel_pitch: 1.2,
                resolution: {
                    height: 1080,
                    width: 1920
                },
                physical_dimensions_inches: {
                    height: 62.36,
                    width: 97.44
                }
            },
            {
                id: 1,
                name: '1.5mm (138" Diag.)',
                pixel_pitch: 1.5,
                resolution: {
                    width: 1920,
                    height: 1080
                },
                physical_dimensions_inches: {
                    height: 75.83,
                    width: 121.38
                }
            },
            {
                id: 2,
                name: '1.9mm (165" Diag.)',
                pixel_pitch: 1.9,
                resolution: {
                    width: 1920,
                    height: 1080
                },
                physical_dimensions_inches: {
                    height: 89.29,
                    width: 145.32
                }
            }
        ]
    },
    optWin: {
        name: 'Opt-Win',
        value: 'optWin',
        ruleset: {
            tileHorizontal: true,
            tileVertical: true,
            canSplitInHalf: false,
            seamless: false,
            borderOptional: true,
            minimum: 1,
            preset_1: false,
            preset_2: false,
            preset_3: false,
            preset_4: false
        },
        variations: [
            {
                id: 0,
                name: '2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 480,
                    width: 288
                },
                physical_dimensions_inches: {
                    height: 49.21,
                    width: 30.31
                }
            },
            {
                id: 1,
                name: '3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 256,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 41.34,
                    width: 31.49
                }
            },
            {
                id: 2,
                name: '4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 208,
                    width: 156
                },
                physical_dimensions_inches: {
                    height: 41.34,
                    width: 31.49
                }
            }
        ]
    },
    optCool: {
        name: 'Opt-Cool',
        value: 'optCool',
        ruleset: {
            tileHorizontal: true,
            tileVertical: false,
            canSplitInHalf: false,
            seamless: false,
            borderOptional: false,
            minimum: 1,
            preset_1: false,
            preset_2: false,
            preset_3: false,
            preset_4: false
        },
        variations: [
            {
                id: 0,
                name: '2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 192,
                    width: 480
                },
                physical_dimensions_inches: {
                    height: 20.24,
                    width: 47.24
                }
            },
            {
                id: 1,
                name: '2.5mm',
                pixel_pitch: 2.5,
                resolution: {
                    height: 192,
                    width: 672
                },
                physical_dimensions_inches: {
                    height: 20.24,
                    width: 66.14
                }
            }
        ]
    },
    optScore: {
        name: 'Opt-Score',
        value: 'optScore',
        ruleset: {
            tileHorizontal: true,
            tileVertical: false,
            canSplitInHalf: false,
            seamless: true,
            borderOptional: false,
            minimum: 1,
            preset_1: false,
            preset_2: false,
            preset_3: false,
            preset_4: false
        },
        variations: [
            {
                id: 0,
                name: '6mm',
                pixel_pitch: 6,
                resolution: {
                    height: 120,
                    width: 360,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 92.9
                }
            },
            {
                id: 1,
                name: '6mm',
                pixel_pitch: 6,
                resolution: {
                    height: 120,
                    width: 440,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 111.8
                }
            },
            {
                id: 2,
                name: '6mm',
                pixel_pitch: 6,
                resolution: {
                    height: 120,
                    width: 560,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 140.1
                }
            },
            {
                id: 3,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 216,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 92.9
                }
            },
            {
                id: 4,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 288,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 111.8
                }
            },
            {
                id: 5,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 336,
                },
                physical_dimensions_inches: {
                    height: 44.13,
                    width: 140.1
                }
            }
        ]
    }

}



export default indoorModules;
