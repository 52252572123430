import React, { useEffect } from 'react'
import { getMargins, roundDown, getObscureMargins } from '../lib/helpers';

const RenderModules = ({
    setPxHeight, setPxWidth, pxHeight, pxWidth,
    setSingleModHeight, setSingleModWidth, singleModHeight, singleModWidth,
    wallAndModuleInfo, setFlexDirection, setXMargins, setYMargins, setTotalMods,
    factor, setFactor, foot, inch, setModulesNeededForHeight, setModulesNeededForWidth,
    modulesNeededForHeight, modulesNeededForWidth, variation, setInnerHeight, setInnerWidth
}) => {

    const {wallHeightFeet, wallHeightInches, wallWidthFeet, wallWidthInches, module} = wallAndModuleInfo;


    //set above variables based on chosen modulei
    setPxWidth(wallWidthFeet * foot + wallWidthInches * inch);
    setPxHeight(wallHeightFeet * foot + wallHeightInches * inch);

    if(variation){
      if (module?.variations[variation]?.physical_dimensions_inches){
        setSingleModHeight(module.variations[variation].physical_dimensions_inches.height * inch);
        setSingleModWidth(module.variations[variation].physical_dimensions_inches.width * inch);
        setModulesNeededForHeight(roundDown(pxHeight / (module.variations[variation].physical_dimensions_inches.height * inch)));
        setModulesNeededForWidth(roundDown(pxWidth / (module.variations[variation].physical_dimensions_inches.width * inch)));
      }else if(module?.physical_dimensions_inches){
	    setSingleModHeight(module.physical_dimensions_inches.height * inch);
	    setSingleModWidth(module.physical_dimensions_inches.width * inch);
	    setModulesNeededForHeight(roundDown(pxHeight / (module.physical_dimensions_inches.height * inch)));
	    setModulesNeededForWidth(roundDown(pxWidth / (module.physical_dimensions_inches.width * inch)));
      }else{
        setSingleModHeight(0);
        setSingleModWidth(0);
        setModulesNeededForHeight(0);
        setModulesNeededForWidth(0);
      }
    }


    let mods = [];
    //let additionalModules = 0;

    setFactor(singleModWidth / singleModHeight);

    //factor of less than one means the module is taller than it is wide
    //a factor of greater than one means it's wider than it is tall,
    //and a factor of 1 means it's a square module

    //this determines how the modules flex direction is set, so that
    //if we can fit additional modules, they will be the correct 
    //orientation when they are added.

    if (factor <= 1) {
        setFlexDirection('row wrap');
    } else {
        setFlexDirection('column wrap');
    }



    //let tempX = getMargins(pxHeight, singleModHeight);
    //let tempY = getMargins(pxWidth, singleModWidth);
  
    //create a tempX and tempY variable to hold the margins, and make sure they round up to the nearest tenth
    let tempX = Math.ceil(getMargins(pxHeight, singleModHeight) * 10) / 10;
    let tempY = Math.ceil(getMargins(pxWidth, singleModWidth) * 10) / 10;
    //now that the margins are exact, we need to determine when they margin is exactly equaly to the module width or height
    //and then remove the margin all together so it fits perfectly
    if (tempX * 2 == singleModWidth && module.name == 'Infinity-SMD') {
        tempX = 0;
        setInnerHeight(singleModHeight * modulesNeededForHeight);
    }

    if (tempY * 2 == singleModHeight && module.name == 'Infinity-SMD') {
      tempY = 0;
      setInnerWidth(singleModWidth * modulesNeededForWidth);
    }

  

    //THE MAGIC 
    //#region checkForAdditionalModules
  
    //modules with a factor of less than one are taller than they are wide

    if (factor < 1 && tempX * 2 >= singleModWidth) {
        let rows;
        let widthLength = modulesNeededForWidth * singleModWidth;
        let remainder = widthLength % singleModHeight;

        rows = roundDown((tempX * 2) / singleModWidth);

       // if (remainder == 0) {
       //     //additionalModules = modulesNeededForWidth;
       //     //console.log(additionalModules);

       //     let newHeight = (modulesNeededForHeight * singleModHeight) + (singleModWidth * rows);
       //     let newMargins = getObscureMargins(pxHeight, newHeight)

       //     setXMargins(newMargins);
       // } else {
            setXMargins(tempX);
       // }
    } else {
        setXMargins(tempX);
    }

    //modules with a factor of greater than one are wider than they are tall
    
    if (factor > 1 && tempY * 2 >= singleModHeight) {
      
        let columns;
        let heightLength = modulesNeededForHeight * singleModHeight;
        let remainder = heightLength % singleModWidth;

        columns = roundDown((tempY * 2) / singleModHeight);


        //if (remainder == 0) {
        //    //additionalModules = modulesNeededForHeight;
        //    //console.log(modulesNeededForHeight);

        //    let newWidth = (modulesNeededForWidth * singleModWidth) + (singleModHeight * columns);
        //    let newMargins = getObscureMargins(pxWidth, newWidth)

        //    setYMargins(newMargins);
        //} else {
            setYMargins(tempY);
        //}
    } else {
        setYMargins(tempY);
    }

    //#endregion checkForAdditionalModules


    let numberOfModulesNeededTotal = modulesNeededForWidth * modulesNeededForHeight;

    if(numberOfModulesNeededTotal >= module?.ruleset.minimum){

      for (let i = 0; i < numberOfModulesNeededTotal; i++) {
          mods.push(<div key={i} className="mod" style={{ width: singleModWidth, height: singleModHeight }}>{i + 1}</div>);
      }

      //add additional modules in a seperate loop to switch the width and height
   //   if (additionalModules > 0) {
   //        let additionalMod = module.variations[0];
   //        let additionalModWidth = additionalMod.physical_dimensions_inches.width * inch;
   //        let additionalModHeight = additionalMod.physical_dimensions_inches.height * inch;
   //        for (let i = 0; i < additionalModules; i++) {
   //           mods.push(<div key={mods.length + 1} className="mod" style={{ width: additionalModWidth, height: additionalModHeight }}>{mods.length + 1}</div>);
   //       }
   //     }
    }

    setTotalMods(numberOfModulesNeededTotal /*+ additionalModules*/);

    return mods;

}

export default RenderModules;
