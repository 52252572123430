const outdoorModules = {
    intelligentM2: {
        name: 'Intelligent-M2',
        value: 'intelligentM2',
        ruleset: {
            minimum: 2, 
            tileVertical: true,
            tileHorizontal: true,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true,
        },
        variations: [
            {
                id: 0,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35
                }
            },
            {
                id: 1,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 2,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 3,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 4,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 5,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 6,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 45,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 7,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 8,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 9,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 75,
                    width: 75
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 10,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 75,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 11,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 12,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 13,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 14,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 15,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 16,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 60,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
        ]
    },
    infinitySMD: {
        name: 'Infinity-SMD',
        value: 'infinitySMD',
        ruleset: {
            minimum: 2,
            tileVertical: true,
            tileHorizontal: true,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '2.9mm',
                pixel_pitch: 2.9,
                resolution: {
                    height: 168,
                    width: 168
                },
                physical_dimensions_inches: {
                    height: 19.68,
                    width: 19.68,
                }
            },
            {
                id: 1,
                name: '3.9mm',
                pixel_pitch: 3.9,
                resolution: {
                    height: 128,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 19.68,
                    width: 19.68,
                }
            },
            {
                id: 2,
                name: '4.8mm',
                pixel_pitch: 4.8,
                resolution: {
                    height: 104,
                    width: 104
                },
                physical_dimensions_inches: {
                    height: 19.68,
                    width: 19.68,
                }
            },
            {
                id: 3,
                name: '6.667mm',
                pixel_pitch: 6.667,//hb?
                resolution: {
                    height: 108,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 50.4,
                }
            },
            {
                id: 4,
                name: '6.667mm',
                pixel_pitch: 6.667,//hb?
                resolution: {
                    height: 144,
                    width: 144
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 5,
                name: '6.667mm',
                pixel_pitch: 6.667,//hb?
                resolution: {
                    height: 144,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 50.4
                }
            },
            {
                id: 6,
                name: '6.667mm',
                pixel_pitch: 6.667,//hb?
                resolution: {
                    height: 180,
                    width: 144
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 37.8
                }
            },
            {
                id: 7,
                name: '6.667',
                pixel_pitch: 6.667,//hb?
                resolution: {
                    height: 180,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 50.4
                }
            },
            {
                id: 8,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 90,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 9,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 90,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 50.4
                }
            },
            {
                id: 10,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 120,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8
                }
            },
            {
                id: 11,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 120,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 50.4
                }
            },
            {
                id: 12,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 150,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 37.8
                }
            },
            {
                id: 13,
                name: '8mm',
                pixel_pitch: 8,//hb?
                resolution: {
                    height: 150,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 50.4
                }
            },
            {
                id: 14,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 72,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 14,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 72,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 50.4
                }
            },
            {
                id: 15,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 96,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8
                }
            },
            {
                id: 16,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 96,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 50.4
                }
            },
            {
                id: 17,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 120,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 37.8
                }
            },
            {
                id: 18,
                name: '10mm',
                pixel_pitch: 10,//hb?
                resolution: {
                    height: 120,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 50.4
                }
            }
        ]
    },
    infinityRGB: {
        name: 'Infinity-RGB',
        value: 'infinityRGB',
        ruleset: {
            tileHorizontal: true,
            tileVertical: true,
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 1,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 2,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 3,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 4,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 5,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 72
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 5,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 6,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 60,
                    width: 80
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 7,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 80,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 8,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 80,
                    width: 80
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 9,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 100,
                    width: 100
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 10,
                name: '12mm',
                pixel_pitch: 12,
                resolution: {
                    height: 100,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 11,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 45,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 12,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 45,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 13,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 14,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 15,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 75,
                    width: 75
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 16,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 75,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 17,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 75,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 18,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 19,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 20,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 21,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 22,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24,
                }
            },
            {
                id: 23,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 60,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35,
                }
            },
            {
                id: 24,
                name: '25mm',
                pixel_pitch: 25,
                resolution: {
                    height: 32,
                    width: 32
                },
                physical_dimensions_inches: {
                    height: 31.5,
                    width: 31.5,
                }
            }
        ]
    },
    infinityGS: {
        name: 'Infinity-GS',
        value: 'infinityGS',
        ruleset: {
            tileHorizontal: true,
            tileVertical: true,
            minimum: 2,
            preset_1: false,
            preset_2: false,
            preset_3: false,
            preset_4: false,
        },
        variations: [
            {
                id: 0,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 45,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 1,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 45,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 2,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 45
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 3,
                name: '16mm',
                pixel_pitch: 16,
                resolution: {
                    height: 60,
                    width: 60
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 4,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35,
                }
            },
            {
                id: 5,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 36,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8,
                }
            },
            {
                id: 6,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 36
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35,
                }
            },
            {
                id: 7,
                name: '20mm',
                pixel_pitch: 20,
                resolution: {
                    height: 48,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8,
                }
            },
            {
                id: 8,
                name: '25mm',
                pixel_pitch: 25,
                resolution: {
                    height: 24,
                    width: 32
                },
                physical_dimensions_inches: {
                    height: 23.06,
                    width: 31.5,
                }
            },
            {
                id: 9,
                name: '25mm',
                pixel_pitch: 25,
                resolution: {
                    height: 24,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 23.06,
                    width: 47.24,
                }
            },
            {
                id: 10,
                name: '25mm',
                pixel_pitch: 25,
                resolution: {
                    height: 32,
                    width: 32
                },
                physical_dimensions_inches: {
                    height: 31.5,
                    width: 31.5,
                }
            },
            {
                id: 11,
                name: '25mm',
                pixel_pitch: 25,
                resolution: {
                    height: 32,
                    width: 48
                },
                physical_dimensions_inches: {
                    height: 31.5,
                    width: 47.27,
                }
            },
        ]
    },
    envisionSMD: {
        name: 'Envision-SMD',
        value: 'envisionSMD',
        ruleset: {
            tileVertical: true,
            tileHorizontal: true, 
            minimum: 2,
            preset_1: true,
            preset_2: true,
            preset_3: true,
            preset_4: true
        },
        variations: [
            {
                id: 0,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 108,
                    width: 144
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35
                }
            },
            {
                id: 1,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 108,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 2,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 144,
                    width: 144
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35
                }
            },
            {
                id: 3,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 144,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8
                }
            },
            {
                id: 4,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 180,
                    width: 144
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24
                }
            },
            {
                id: 5,
                name: '6.667mm',
                pixel_pitch: 6.667,
                resolution: {
                    height: 180,
                    width: 192
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35
                }
            },
            {
                id: 6, 
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 90,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35
                }
            },
            {
                id: 7,
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 90,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 8,
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 120,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35
                }
            },
            {
                id: 9,
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 120,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8
                }
            },
            {
                id: 10,
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 150,
                    width: 120
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24
                }
            },
            {
                id: 11,
                name: '8mm',
                pixel_pitch: 8,
                resolution: {
                    height: 150,
                    width: 160
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35
                }
            },
            {
                id: 12,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 28.35
                }
            },
            {
                id: 13,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 72,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 28.35,
                    width: 37.8
                }
            },
            {
                id: 14,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 28.35
                }
            },
            {
                id: 15,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 96,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 37.8,
                    width: 37.8
                }
            },
            {
                id: 16,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 96
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 47.24
                }
            },
            {
                id: 16,
                name: '10mm',
                pixel_pitch: 10,
                resolution: {
                    height: 120,
                    width: 128
                },
                physical_dimensions_inches: {
                    height: 47.24,
                    width: 28.35
                }
            },
        ]
    },
    infinityOneSMD: {
        name: "Infinity-One SMD",
        value: "infinityOneSMD",
        ruleset: {
          tileVertical: true,
          tileHorizontal: true, 
          minimum: 2,
          preset_1: true,
          preset_2: true,
          preset_3: true,
          preset_4: true,
        },
        variations: [
            {
                id: 0,
                name: '9.525mm',
                pixel_pitch: 9.525,
                resolution: {
                    height: 32,
                    width: 64
                }, 
            }
        ],
	physical_dimensions_inches: {
                    height: 12,
                    width: 24
	}
    }
}

export default outdoorModules;
